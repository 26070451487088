import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { RecommendationPeriodicalModel } from './recommendation-settings.model';
import moment from 'moment';
import { PeriodicalRecommendationStatusEnum } from './periodical-recommendation-status.enum';

export const listColumnConfig: (_this: { translocoService: TranslocoService }) => any = (_this: {
  translocoService: TranslocoService;
}): GridColumnModel<RecommendationPeriodicalModel>[] => {
  return [
    {
      name: 'current_date',
      displayNameTranslateKey: 'PERIODICAL.LIST.COLUMN.PERIOD.HEADER',
      cellContentTemplateCallback: model => {
        const date = moment(model.current_date);
        return date.format('MMMM, YYYY');
      },
    },
    {
      name: 'status',
      displayNameTranslateKey: 'PERIODICAL.LIST.COLUMN.NOTE.HEADER',
      cellContentTemplateCallback: model => {
        return _this.translocoService.translate(`PERIODICAL.STATUS.TOOLTIP.${model.status}`);
      },
    },
    {
      name: 'check_result_header',
      displayNameTranslateKey: 'PERIODICAL.LIST.COLUMN.RECOMMENDATION_CHECK_RESULT.HEADER',
      cellContentTemplateCallback: model => {
        return '';
      },
      contentTemplateRefIndex: 0,
    },
    {
      name: 'process_result_header',
      displayNameTranslateKey: 'PERIODICAL.LIST.COLUMN.RECOMMENDATION_PROCESS_RESULT.HEADER',
      /*
      cellContentTemplateCallback: model => model.status,
*/
      cellContentTemplateCallback: model => {
        return '';
      },
      contentTemplateRefIndex: 1,
    },
  ];
};
